<template>
  <div class="e-module e-module--limited e-module--shadow">
    <div class="e-form e-form--register">
      <!-- Title -->
      <div class="e-form__title">
        <h1>{{ $t('system.forgotPassword.title') }}</h1>
      </div>

      <div class="e-form__content" v-html="$sanitize($t('system.forgotPassword.body'))" />

      <el-form
        ref="forgotPasswordForm"
        :model="forgotPasswordForm"
        :rules="rules"
        method="post"
      >
        <!-- Errormessages -->
        <el-alert
          v-for="(item, index) in errors.messages"
          :key="index"
          :title="$t(index)"
          type="error"
          v-html="$sanitize($t(item))"
        />
        <!-- /Errormessages -->

        <el-alert
          v-if="errorWithLink"
          :title="$t('system.attention')"
          type="error"
        >
          {{ errorWithLink.prepend }}
          <router-link :to="{ name: errorWithLink.routeName }">
            {{ errorWithLink.linkText }}
          </router-link>
          {{ errorWithLink.append }}
        </el-alert>
        <!-- /Errormessages -->

        <!-- Successmessage -->
        <el-alert
          v-if="formSuccess"
          :title="$t('messages.user.forgotPassword.sentSuccess')"
          type="success"
        />
        <!-- /Errormessages -->

        <!-- Row -->
        <el-form-item :label="$t('system.login.username')" prop="username">
          <el-input
            v-model="forgotPasswordForm.username"
            :placeholder="$t('system.login.username')"
            type="text"
            name="username"
          />
        </el-form-item>

        <!-- Row -->
        <el-form-item :label="$t('system.register.email')" prop="email">
          <el-input
            v-model="forgotPasswordForm.email"
            :placeholder="$t('system.register.emailPlaceholder')"
            type="email"
            name="email"
          />
        </el-form-item>

        <!-- Row -->
        <div class="e-form__row e-form__row--submit">
          <router-link
            :to="{ name: 'index' }"
            class="e-btn e-btn--ghost e-btn--large"
          >
            {{ $t('system.back') }}
          </router-link>

          <el-button
            :loading="loading"
            type="secondary"
            size="large"
            native-type="submit"
            @click.prevent="submitForm('forgotPasswordForm')"
          >
            {{ $t('system.forgotPassword.submit') }}
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import api from 'api'
import { userActions } from 'mixins'

export default {
  mixins: [userActions],
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!this.validateMail(value)) {
        callback(new Error(this.$t('messages.user.email.invalid')))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      forgotPasswordForm: {
        username: '',
        email: ''
      },
      rules: {
        username: [
          {
            required: true,
            message: this.$t('messages.user.username.empty'),
            trigger: 'blur'
          }
        ],
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }]
      },
      errorWithLink: false,
      errors: [],
      formSuccess: false
    }
  },
  methods: {
    submitForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.sendForm()
          } else {
            return false
          }
        })
      }
    },

    sendForm: async function() {
      this.loading = true
      this.formSuccess = false
      this.errors = []
      this.errorWithLink = false

      try {
        const response = await api.call('forgotPassword', false, {
          username: this.forgotPasswordForm.username,
          email: this.forgotPasswordForm.email
        })

        if (response.status === 200) {
          this.formSuccess = true
          this.forgotPasswordForm.username = ''
          this.forgotPasswordForm.email = ''
        }
      } catch (error) {
        if (
          error.messages &&
          error.messages[0] !== 'messages.user.account.unconfirmed'
        ) {
          this.errors = error
        } else {
          this.errorWithLink = {
            prepend: this.$t('messages.user.account.unconfirmed.prepend'),
            linkText: this.$t('messages.user.account.unconfirmed.linkText'),
            routeName: 'resendConfirmMail',
            append: this.$t('messages.user.account.unconfirmed.append')
          }
        }
      } finally {
        this.loading = false
      }
    },

    validateMail(email) {
      // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>
