<template>
  <div class="e-module e-module--limited e-module--shadow">
    <div class="e-form e-form--register">
      <!-- Title -->
      <div class="e-form__title">
        <h1>{{ $t('system.resendConfirm.title') }}</h1>
      </div>

      <!-- Errormessages -->
      <el-alert
        v-for="(item, index) in errors.messages"
        :key="index"
        :title="$t(index)"
        type="error"
        style="margin-bottom: 20px;"
        v-html="$sanitize($t(item))"
      />

      <!-- Errormessages -->
      <el-alert
        v-if="success"
        type="success"
        title=""
        style="margin-bottom: 20px;"
        v-html="$sanitize($t(success))"
      />

      <div class="e-form__content" v-html="$sanitize($t('system.resendConfirm.body'))" />

      <el-form ref="resendConfirmForm" :model="resendConfirmForm" method="post">
        <!-- Row -->
        <el-form-item :label="$t('system.register.email')" prop="email">
          <el-input
            v-model="resendConfirmForm.email"
            :placeholder="$t('system.register.emailPlaceholder')"
            type="email"
            name="email"
          />
        </el-form-item>

        <!-- Row -->
        <div class="e-form__row e-form__row--submit">
          <router-link
            :to="{ name: 'index' }"
            class="e-btn e-btn--ghost e-btn--large"
          >
            {{ $t('system.back') }}
          </router-link>

          <el-button
            :loading="loading"
            type="secondary"
            size="large"
            native-type="submit"
            @click.prevent="submitForm('resendConfirmForm')"
          >
            {{ $t('system.forgotPassword.submit') }}
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import api from 'api'

export default {
  data() {
    return {
      loading: false,
      resendConfirmForm: {
        email: ''
      },
      rules: {},
      errors: [],
      success: false,
      formSuccess: false
    }
  },
  methods: {
    submitForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.sendForm()
          } else {
            return false
          }
        })
      }
    },

    async sendForm() {
      this.loading = true
      this.formSuccess = false
      this.errors = []

      try {
        const response = await api.call('resendConfirm', false, {
          email: this.resendConfirmForm.email
        })

        if (response.status === 200) {
          this.formSuccess = true
          this.success = 'messages.user.resendConfirm.success'
          this.resendConfirmForm.email = ''
        }
      } catch (e) {
        this.$error(e)
        this.errors = e
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
