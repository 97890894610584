<template>
  <div class="e-overall e-overall--login">
    <div
      v-if="!$route.meta.requiresAuth"
      v-show="!offcanvasOpen"
      class="e-canvas__background"
    />

    <transition name="slide-to-left">
      <div v-show="!offcanvasOpen" class="e-canvas e-canvas--login">
        <TheHeader />
        <div
          class="login-container"
          :class="{ 'login-container--show-event': showEventBox }"
        >
          <component :is="formType" />
        </div>
        <TheFooter :hide-logos="true" />
      </div>
    </transition>

    <transition name="slide-from-right">
      <TheOffcanvas v-show="offcanvasOpen" />
    </transition>

    <el-dialog
      :title="dialogTitle.registered"
      :visible.sync="registerDialog"
      custom-class="el-dialog--small"
    >
      <p
        v-html="$sanitize(
          $route.query.email === '0'
            ? $t('messages.user.register.successNoEmail')
            : $t('messages.user.register.success')
        )"
      />

      <span slot="footer" class="dialog-footer">
        <el-button type="tertiary" @click="registerDialog = false">
          {{ $t('system.understood') }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="dialogTitle.activated"
      :visible.sync="activationDialog"
      custom-class="el-dialog--small"
    >
      <p v-html="$sanitize($t('messages.user.activation.success'))" />

      <span slot="footer" class="dialog-footer">
        <el-button type="tertiary" @click="activationDialog = false">
          {{ $t('system.understood') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TheFooter from 'molecules/TheFooter/TheFooter'
import TheHeader from 'molecules/TheHeader/TheHeader'
import LoginForm from 'organisms/Forms/Login/Loginform'
import PasswordForm from 'organisms/Forms/PasswordForgot/PasswordForgot'
import PasswordResetForm from 'organisms/Forms/PasswordReset/PasswordReset'
import ResendConfirm from 'organisms/Forms/ResendConfirm/ResendConfirm'
import UserForm from 'organisms/Forms/UserForgot/UserForgot'
import TheOffcanvas from 'molecules/TheOffcanvas/TheOffcanvas'
import CounselingEventsTeaserBox from 'organisms/CounselingEventsTeaserBox/CounselingEventsTeaserBox'

export default {
  components: {
    TheFooter,
    TheHeader,
    LoginForm,
    TheOffcanvas,
    PasswordResetForm,
    PasswordForm,
    ResendConfirm,
    UserForm,
    CounselingEventsTeaserBox
  },
  data() {
    return {
      registerDialog: false,
      activationDialog: false,
      errorMessage: '',
      dialogTitle: {
        registered: this.$t('messages.user.register.successTitle'),
        activated: this.$t('messages.user.activation.title')
      }
    }
  },
  computed: {
    ...mapGetters([
      'offcanvasOpen',
      'userInfo',
      'counselingEvents',
      'counselingCenterId',
      'counselingCenters',
      'counselingEventTotalCount'
    ]),
    formType() {
      switch (this.$route.name) {
        case 'forgotPassword':
          return 'PasswordForm'

        case 'resetPassword':
          return 'PasswordResetForm'

        case 'resendConfirmMail':
          return 'ResendConfirm'

        case 'forgotUsername':
          return 'UserForm'

        default:
          return 'LoginForm'
      }
    },
    showEventBox() {
      if (this.formType !== 'LoginForm') {
        return false
      }
      if (this.userInfo && this.userInfo.id) {
        return false
      }

      if (!this.counselingCenterId) {
        return false
      }
      const center = this.counselingCenters.find(
        el => el.id === this.counselingCenterId
      )
      if (!center) {
        return false
      }
      return center.counselingEventsEnabled
    }
  },
  mounted() {
    if (this.$route.query.registered === 'true') {
      this.registerDialog = true
    } else if (this.$route.query.activated === 'true') {
      this.activationDialog = true
    }
  }
}
</script>

<style lang="scss">
@import '_assets/userlogin';

.login-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  flex-grow: 0;
  justify-items: center;
  align-items: start;
  margin-top: auto !important;
  // @include break(l) {
  //   gap: 8rem;
  //   grid-template-columns: repeat(2,minmax(0,1fr));
  // }
}
.ce-teaser-box {
  z-index: 5;
  flex-shrink: 0;
  margin: 2rem 0;

  @include break(l) {
    margin: 0;
  }
}
</style>
